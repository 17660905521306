import { detect } from 'detect-browser'

const browser = detect()

export const buildContentfullImageUrl = (src, dimension = { w: '' }) => {

    const width = dimension.w

    const quality = dimension.q

    if (typeof src !== 'string' || !src) return ''

    let end_point_url = src.startsWith('http') ? src : `https:${src}`

    const { isFormatAvailable, isQueryAvailable, isWidthAvailbale, isQualityAvailable } = URLparser(end_point_url)

    if (isFormatAvailable && isWidthAvailbale) return end_point_url

    if (!src.includes('svg')) {

        let image_format = 'webp'

        if (!isFormatAvailable) {

            if (browser && browser.name === 'chrome') {

                if (parseInt(browser.version) > 84) {

                    image_format = 'avif'

                }

            } else if (browser && browser.name === 'safari') {

                image_format = 'png'

            }

            end_point_url = `${end_point_url}${isQueryAvailable ? '&' : '?'}fm=${image_format}`

        }

        if (width && !isWidthAvailbale) {

            end_point_url = `${end_point_url}&w=${width}`

        }

        if (!isQualityAvailable) end_point_url = `${end_point_url}&q=${quality || 75}`

    }

    return end_point_url

}

const URLparser = (url = '') => {

    try {

        const loc = new URL(url)

        const serachParams = loc.searchParams

        return {

            isQueryAvailable: !!loc.search?.trim(),

            isWidthAvailbale: serachParams.has('w'),

            isFormatAvailable: serachParams.has('fm'),

            isQualityAvailable: serachParams.has('q'),

        }

    } catch (msg) {

        console.error(msg)

    } 

}

const loader = ({ src, quality }) => {

    function isImage(url) {

        return /\.(svg||gif||png)$/.test(url)

    }

    function isJpg(url) {

        return /\.(jpg||jpeg)$/.test(url)

    }

    if (isJpg(src)) {

        return `${src}?fm=webp&q=${quality || 95}`

    } else if (isImage(src)) {

        return src

    } else return `${src}&q=${quality || 95}`

}

export default {

    buildContentfullImageUrl,

    loader

}