/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

function DialogTitle(props) {
    const {
        children,
        className,
        component: Component,
        ...other
    } = props;

    return (
        <Component className={className} {...other}>
            {children}
        </Component>
    );
    // return <Component className={ className } {...other} />;
}

DialogTitle.defaultProps = {
    component: 'span',
};

DialogTitle.propTypes = {
    component: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default DialogTitle;
