/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function DialogContent(props) {
    const {
        children,
        component: Component,
        ...other
    } = props;

    return (
        <Component className={classNames} {...other}>
            {children}
        </Component>
    );
}
DialogContent.defaultProps = {
    component: 'div',
};

DialogContent.propTypes = {
    component: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default DialogContent;
