export const membersOnlyCampaign = [
    'プロモーションコード：WHISPER2204',
    '注意事項：遷移先ページの対象商品のみ使用可能です。',
    'お会計の際にプロモーションコードを入力してください。',
    '実施期間: 2022/4/29(金)～5/31(火)',
];
export const campaignHeading = '＜会員限定キャンペーン＞';
export const campaignSubHeading1 = '30% off キャンペーン対象商品は';
export const campaignSubHeading2 = 'から。';
export const buttonText = '今すぐチェックする';
export const linkText = 'こちら';

export const reviewSeoDetails = {
    breadcrumbsTitle: 'うすさら安心リニューアル記念キャンペーン',
    title: 'Amazonギフト券がもらえるレビュー投稿キャンペーン',
    description: 'キャンペーン期間中、ウィスパー会員に登録（無料）いただいており、「マイレピ」にウィスパー製品のレビューを投稿いただいた方の中から、抽選で50名様にAmazonギフト券5,000円分をプレゼント！',
    url: '/campaign/reviewcampaign/',
    ogType: 'website',
    ogDescription:
'キャンペーン期間中、ウィスパー会員に登録（無料）いただいており、「マイレピ」にウィスパー製品のレビューを投稿いただいた方の中から、抽選で50名様にAmazonギフト券5,000円分をプレゼント！ ',
    ogImage: {
        url: 'https://images.ctfassets.net/8g0fievzqg8k/1xYSc0d2UTlal28HNKCF5S/f2454808263615cb9d7944c6025f6137/KOI_OPG_kv.jpg',
        width: 1201,
        height: 631,
        contentType: 'image/jpeg',
    },
    canonical: '/campaign/reviewcampaign',
};

export const myRepiAllBrands = 'https://www.myrepi.com/brands/whisper/';

export const tsuruhaCampaignSeoDetails = {
    breadcrumbsTitle: 'ツルハポイントプレゼントキャンペーン',
    title: 'ツルハポイントプレゼントキャンペーン',
    title1: 'ツルハポイントプレゼントキャンペーン',
    keywords: '大人用おむつ、おむつ大人用、尿もれおむつ、大人おむつ、ドラッグストア、ツルハポイント、キャンペーン',
    description: 'ウィスパーを1個お買い上げごとにツルハグループポイント50ポイントプレゼント！ウィスパー全商品対象。2022年6月30日(木)まで。',
    url: '/tsuruha202206campaign',
    ogType: 'website',
    ogDescription:
'ウィスパーを1個お買い上げごとにツルハグループポイント50ポイントプレゼント！ウィスパー全商品対象。2022年6月30日(木)まで。',
    ogImage: {
        url: 'https://images.ctfassets.net/8g0fievzqg8k/3C6OxYIENo7391LDvHigIK/2fc3517b2d0a5fcb3f4fe0c4ec94cadd/tsuruhagroup_PC.png',
        width: 1201,
        height: 631,
        contentType: 'image/jpeg',
    },
    canonical: '/tsuruha202206campaign',
};

export const erroMessageFromJanrain = {
    passwordNotAcceptable: 'password is unacceptable',
    authCodeIsNotValid: 'authorization_code is not valid',
    passwordNotAcceptableTranslate: 'このパスワードは最近使用したものと同じため利用できません。別のものを入力してください',
    authCodeIsNotValidTranslate: 'コードの有効期限が切れています。もう一度パスワードをリセットしてください'
}
