import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Modal extends Component {
    componentDidMount() {
        const { open } = this.props;
        if (open) {
            this.handleOpen();
        }
    }

    componentWillUnmount() {
        const { open } = this.props;
        if (open) {
            this.handleClose();
        }
    }

  handleOpen = () => {
      document.body.classList.add('overflow-hidden');
      document.addEventListener('click', this.handleOutsideClick, false);
      document.addEventListener('keydown', this.handleDocumentKeyDown);
  };

  handleClose = () => {
      document.querySelector('body').classList.remove('overflow-hidden');
      window.removeEventListener('keydown', this.handleDocumentKeyDown);
      document.removeEventListener('click', this.handleOutsideClick, false);
  };

  handleDocumentKeyDown = (event) => {
      const { onEscapeKeyDown, onClose, disableEscapeKeyDown } = this.props;
      if (event.key !== 'Escape') {
          return;
      }
      if (onEscapeKeyDown) {
          onEscapeKeyDown(event);
      }

      if (!disableEscapeKeyDown && onClose) {
          onClose(event, 'escapeKeyDown');
      }
  };

  handleBackdropClick = (event) => {
      const { onBackdropClick, disableBackdropClick, onClose } = this.props;
      if (event.target !== event.currentTarget) {
          return;
      }

      if (onBackdropClick) {
          onBackdropClick(event);
      }

      if (!disableBackdropClick && onClose) {
          onClose(event, 'backdropClick');
      }
  };

  render() {
      const { backdropStyle, modalStyle, children } = this.props;

      return (
          <div
              role="presentation"
              onKeyPress={this.handleBackdropClick}
              className={backdropStyle}
              onClick={this.handleBackdropClick}
          >
              <div className={modalStyle}>{children}</div>
          </div>
      );
  }
}

Modal.propTypes = {
    open: PropTypes.bool.isRequired,
    onEscapeKeyDown: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onBackdropClick: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    modalStyle: PropTypes.string,
    backdropStyle: PropTypes.string,
    disableBackdropClick: PropTypes.bool,
    disableEscapeKeyDown: PropTypes.bool,
    onClose: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

Modal.defaultProps = {
    onEscapeKeyDown: null,
    onBackdropClick: null,
    modalStyle: '',
    backdropStyle: '',
    disableBackdropClick: false,
    disableEscapeKeyDown: false,
    onClose: null,
};

export default Modal;
