/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

function DialogActions(props) {
    const { children, className, ...other } = props;

    return (
        <div className={className} {...other}>
            {children}
        </div>
    );
}

DialogActions.propTypes = {
    children: PropTypes.node.isRequired,
    /**
   * @ignore
   */
    className: PropTypes.string,
};

DialogActions.defaultProps = {
    className: '',
};

export default DialogActions;
