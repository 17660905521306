import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';

class Dialog extends PureComponent {
    // constructor(props) {
    //   super(props);
    //   this.state = {
    //     showModal: false,
    //   };
    // }

    // handleToggleModal() {
    //   this.setState({ showModal: !this.state.showModal });
    // }

    render() {
        const {
            disableBackdropClick,
            disableEscapeKeyDown,
            // fullScreen,
            // fullWidth,
            // maxWidth,
            onBackdropClick,
            onEscapeKeyDown,
            children,
            modalStyle,
            onClose,
            open,
        } = this.props;

        // const { showModal } = this.state;
        return (
            <div>
                {open && (
                    <Modal
                        disableBackdropClick={disableBackdropClick}
                        disableEscapeKeyDown={disableEscapeKeyDown}
                        onBackdropClick={onBackdropClick}
                        onEscapeKeyDown={onEscapeKeyDown}
                        onClose={onClose}
                        modalStyle={modalStyle}
                        open={open}
                    >
                        {children}
                    </Modal>
                )}
            </div>
        );
    }
}

Dialog.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    modalStyle: PropTypes.string.isRequired,
    disableBackdropClick: PropTypes.bool,
    disableEscapeKeyDown: PropTypes.bool,
    // fullScreen: PropTypes.bool,
    // fullWidth: PropTypes.bool,
    // maxWidth: PropTypes.string,
    onBackdropClick: PropTypes.func,
    onEscapeKeyDown: PropTypes.func,
};

Dialog.defaultProps = {
    disableBackdropClick: false,
    disableEscapeKeyDown: false,
    // fullScreen: false,
    // fullWidth: false,
    // maxWidth: 'sm',
    onClose: () => null,
    onBackdropClick: () => null,
    onEscapeKeyDown: () => null,
};

export default Dialog;
